/** @format */

import Metismenu from "metismenujs";
import React, {
  Component,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "context/ThemeContext";
import { useSelector } from "react-redux";
import { getProviderProfile } from "store/actions/profile";
import { useDispatch } from "react-redux";
import MMLogo from "images/3MTT/fg.png";
import { FadeLoader } from "react-spinners";
import { Path } from "utils/constant.data";
import {
  mapStatusHeading,
  mapFellowStatusInformation,
  mapStatus,
  telegram_links,
  validStatuses,
} from "utils/strings";
import { checkProfileCompletion } from "utils/functions";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() { }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  const profileState = useSelector((state) => state.profile);


  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProviderProfile()).then(() => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);
  }, []);

  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const pathName = window.location.pathname;
  let path = pathName.split("/");
  path = path[path.length - 1];

  let deshBoard = ["dashboard"],
    profile = ["profile"],
    community = ["community"],
    course = ["courses"],
    faqs = ["faqs"],
    survey = ["surveys"],
    attendance = ["attendance", "confirm-attendance"],
    certificates = ["certificates"],
    support = ["support"];

  const _profile = profileState?.profileInfo?.data?.profile || null;
  const fellowState = _profile?.residence?.toLowerCase();
  const show = validStatuses.includes(_profile?.status?.toLowerCase());
  const cohort = Number(_profile?.cohort?.rank);


  const currentProfileState = checkProfileCompletion(_profile);

  const _communities = [
    {
      title: "Webinars",
      path: Path.COMMUNITY_WEBINARS,
    },
    {
      title: "Resources",
      path: Path.COMMUNITY_RESOURCES,
    },
  ];

  return (
    <div
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
        }`}
    >


      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.FELLOW_DASHBOARD}>
              <i className="bi bi-grid"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>

          <li className={`${profile.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.FELLOW_PROFILE}>
              <i className="bi bi-person"></i>
              <span className="nav-text">Profile</span>
            </Link>
          </li>

          {
            currentProfileState?.showBanner ? null : (
              <>
                {_profile &&
                  (_profile?.status?.toLowerCase() === "selected" ||
                    _profile?.status?.toLowerCase() === "certified" ||
                    _profile?.status?.toLowerCase() === "application_completed") && (


                    <li
                      className={`course-link-item ${community.includes(path) ? "active-parent" : ""
                        }`}
                    >
                      <Link className="has-arrow parent-link" to="#">
                        <i className="bi bi-book"></i>
                        <span className="nav-text">Community</span>
                      </Link>
                      <ul>
                        <>
                          {_communities.map((_) => {
                            return (
                              <li>
                                <Link
                                  className={`${path === _?.path ? "mm-active" : ""
                                    } child-link`}
                                  to={_?.path}
                                >
                                  {_?.title}
                                </Link>
                              </li>
                            );
                          })}

                          {_profile?.status?.toLowerCase() ===
                            "application_completed" && (
                              <li>
                                <Link
                                  className={`${path === Path.COMMUNITY_COURSE ? "mm-active" : ""
                                    } child-link`}
                                  to={Path.COMMUNITY_COURSE}
                                >
                                  Foundational Course
                                </Link>
                              </li>
                            )}
                        </>
                      </ul>
                    </li>
                  )}

                {_profile &&
                  show &&
                  profileState?.profileInfo?.data?.profile?.consent_accepted && (
                    <li
                      className={`course-link-item ${course.includes(path) ? "active-parent" : ""
                        }`}
                    >
                      <Link className="has-arrow parent-link" to="#">
                        <i className="bi bi-book"></i>
                        <span className="nav-text">My Courses </span>
                      </Link>
                      <ul>
                        {cohort === 2 ? null : (
                          <Link
                            className={`${path === Path.FELLOW_MY_COURSES ? "mm-active" : ""
                              } child-link`}
                            to={`${Path.FELLOW_MY_COURSES}?course=online`}
                          >
                            Online Learning Content
                          </Link>
                        )}

                        {profileState?.profileInfo?.data?.profile?.provider && (
                          <Link
                            className={`${path === Path.FELLOW_MY_COURSES ? "mm-active" : ""
                              } child-link`}
                            to={`${Path.FELLOW_MY_COURSES}?course=applied`}
                          >
                            Applied Learning Content
                          </Link>
                        )}

                        {profileState?.profileInfo?.data?.profile?.provider && (
                          <Link
                            className={`${path === Path.FELLOW_ASSESMENTS ? "mm-active" : ""
                              } child-link`}
                            to={Path.FELLOW_ASSESMENTS}
                          >
                            <span className="nav-text">Assessments</span>
                          </Link>
                        )}
                      </ul>
                    </li>
                  )}



                {_profile &&
                  show &&
                  profileState?.profileInfo?.data?.profile?.provider && (
                    <>
                      {/* <li className={`${pathIncludesForum ? "mm-active" : ""}`}>
                  <Link className="ai-icon" to={Path.FELLOW_FORUM}>
                    {" "}
                    <i className="bi bi-collection"></i>{" "}
                    <span className="nav-text">Forum</span>
                  </Link>
                </li> */}

                      <li
                        className={`${attendance.includes(path) ? "mm-active" : ""}`}
                      >
                        <Link className="ai-icon" to={Path.FELLOW_CONFIRM_ATTENDANCE}>
                          {" "}
                          <i className="bi bi-calendar"></i>{" "}
                          <span className="nav-text">ALC Attendance</span>
                        </Link>
                      </li>
                    </>
                  )}

                <li className={`${faqs.includes(path) ? "mm-active" : ""}`}>
                  <Link className="ai-icon" to={Path.FELLOW_JOBS}>
                    {" "}
                    <i className="bi bi-basket3"></i>{" "}
                    <span className="nav-text">Jobs & Gigs</span>
                  </Link>
                </li>

                <li className={`${survey.includes(path) ? "mm-active" : ""}`}>
                  <Link className="ai-icon" to={Path.FELLOW_SURVEYS}>
                    {" "}
                    <i className="bi bi-question-square"></i>{" "}
                    <span className="nav-text">Surveys</span>
                  </Link>
                </li>

                {_profile &&
                  show &&
                  (profileState?.profileInfo?.data?.profile?.cohort?.rank === 1 ||
                    profileState?.profileInfo?.data?.profile?.cohort?.rank === 2) && (
                    <li
                      className={`${certificates.includes(path) ? "mm-active" : ""}`}
                    >
                      <Link
                        className="ai-icon"
                        to={Path.FELLOW_ONLINE_LEARNING_CERTIFICATES}
                      >
                        <i className="bi bi-patch-check"></i>
                        <span className="nav-text">Certificates</span>
                      </Link>
                    </li>
                  )}

                <li className={`${support.includes(path) ? "mm-active" : ""}`}>
                  <Link className="ai-icon" to={Path.FELLOW_SUPPORT_PAGE}>
                    {" "}
                    <i className="bi bi-headset"></i>{" "}
                    <span className="nav-text">Support</span>
                  </Link>
                </li>
              </>
            )
          }




          <li className={`${faqs.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.FELLOW_FAQS}>
              {" "}
              <i className="bi bi-info-circle"></i>{" "}
              <span className="nav-text">Faqs</span>
            </Link>
          </li>
        </MM>

        {profileState?.isLoading ? (
          <div className="d-flex justify-content-center">
            <FadeLoader color="#26a65b" />
          </div>
        ) : (
          <Fragment>
            <div className="d-flex justify-content-center align-items-center">
              {_profile?.status &&
                _profile?.status?.toLowerCase() === "application_completed" && (
                  <div className="status-badge">
                    <div className="d-flex flex-column">
                      <span>Learning</span>
                      <span>Community </span>
                      <span> Member</span>
                    </div>
                  </div>
                )}
            </div>

            <div className="plus-box">
              <div className="d-flex align-items-center">
                <h5>Your 3MTT Status</h5>
              </div>
              <p>
                {_profile?.status &&
                  mapFellowStatusInformation(_profile?.status)}
              </p>

              {_profile?.status ? (
                _profile?.status?.toLowerCase() === "selected" ? (
                  <button className="btn btn-primary btn-block" disabled={true}>
                    Selected
                  </button>
                ) : (
                  <div
                    to={"#"}
                    className={`btn ${mapStatus(
                      _profile?.status
                    )} btn-sm btn-block `}
                  >
                    {_profile?.status && mapStatusHeading(_profile?.status)}
                  </div>
                )
              ) : null}

              <button
                className="btn btn-outline btn-block btn-telegram"
                onClick={() => {
                  window.open(telegram_links[fellowState], "_blank");
                }}
              >
                Join your 3MTT Community
              </button>
            </div>
          </Fragment>
        )}

        <Link to={Path.FELLOW_DASHBOARD} className="ministry-logo">
          <figure>
            <img src={MMLogo} alt="" />
          </figure>
        </Link>

        <div className="copyright">
          <p>
            <b>FMoCIDE</b> © {new Date().getFullYear()} All Rights Reserved
          </p>
          <p className="fs-12 text-center">
            <span className="heart" style={{ display: "none" }}></span>
          </p>
        </div>
      </PerfectScrollbar>



    </div>
  );
};

// eslint-disable-next-line no-lone-blocks

/*  <li className={`${perks.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to={Path.FELLOW_PERKS}>
              {" "}
              <i className="bi bi-info-circle"></i>{" "}
              <span className="nav-text">3MTT Perks </span>
            </Link>
          </li> */

export default SideBar;
