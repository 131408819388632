/** @format */

import React, { useState, useEffect } from "react";

import ProviderLayout from "views/providers/Provider";
import { FadeLoader } from "react-spinners";
import AttendaceReportTable from "views/providers/components/table/fellows/AttendanceReportTable";
import {
  useGetAttendanceReportQuery,
  useExportProviderAttendanceReportQuery,
} from "store/services/providerAPI";
import { useLocation, useNavigate } from "react-router-dom";
import useApprovedProvider from "hooks/useApprovedProvider";
import { toast } from "react-toastify";

const ProviderAttendanceReport = () => {
  useApprovedProvider();
  const [skip, setSkip] = useState(false);
  const [exportSkip, setExportSkip] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const [filter, setFilter] = useState(
    location?.search?.length > 0
      ? location?.search?.slice(1)
      : `page=1&page_size=100`
  );

  const {
    isLoading: exporting,
    isFetching: isExporting,
    isSuccess,
    isError
  } = useExportProviderAttendanceReportQuery(filter, {
    skip: exportSkip,
  });

  const {
    data,
    isLoading: fetching,
    isFetching,
  } = useGetAttendanceReportQuery(filter, {
    skip: skip,
  });

  useEffect(() => {
    if (filter && filter.length > 0) {
      const fullPath = `${location?.pathname}?${filter}`;
      setSkip(false);
      navigate(fullPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`✔️ Export in progress!`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setExportSkip(true); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(`✔️ Export failed!`, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
      });
      setExportSkip(true); 
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <ProviderLayout>
      <div className="row">
        <section className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-end mb-4">
            {filter.includes("export=yes") && (
              <button
                onClick={() => {
                  setFilter(filter.replace("&export=yes", "").trim());
                  setSkip(true);
                  setExportSkip(true);
                }}
                className="btn text-danger"
              >
                Reset Export
              </button>
            )}

            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                setExportSkip(false);
              }}
            >
              {exporting || isExporting ? (
                <span>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Exporting...
                </span>
              ) : (
                <span>Export</span>
              )}
            </button>
          </div>

          {fetching ? (
            <div className="d-flex justify-content-center align-items-center height-15 bg-white ">
              <FadeLoader color="#36d7b7" />
            </div>
          ) : (
            <section className="d-flex flex-column">
              <AttendaceReportTable
                messages={data?.data?.results}
                metadata={data?.data?.metadata}
                setFilter={setFilter}
                setSkip={setSkip}
                filtering={fetching || isFetching}
              />
            </section>
          )}
        </section>
      </div>
    </ProviderLayout>
  );
};
export default ProviderAttendanceReport;
